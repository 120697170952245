import { render, staticRenderFns } from "./ProjectTimeline.vue?vue&type=template&id=b6d892c8"
import script from "./ProjectTimeline.vue?vue&type=script&lang=js"
export * from "./ProjectTimeline.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b6d892c8')) {
      api.createRecord('b6d892c8', component.options)
    } else {
      api.reload('b6d892c8', component.options)
    }
    module.hot.accept("./ProjectTimeline.vue?vue&type=template&id=b6d892c8", function () {
      api.rerender('b6d892c8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/timeline/ProjectTimeline.vue"
export default component.exports