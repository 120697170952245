var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container",
      class: [_vm.cur_node.meta.status, _vm.cur_node.meta.type],
    },
    [
      _c(
        "div",
        {
          class: ["label", `depth-${_vm.depth}`],
          style: _vm.labelCSS,
          on: {
            click: function ($event) {
              return _vm.$emit("click", _vm.cur_node)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.cur_node.label) + " ")]
      ),
      _vm.has_children
        ? _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.$emit("toggle:hide")
                },
              },
            },
            [
              _vm.children_collapsed
                ? _c("span", { staticClass: "el-icon-caret-right" })
                : _c("span", { staticClass: "el-icon-caret-bottom" }),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }