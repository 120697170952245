var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.active
    ? _c(
        "div",
        {
          staticClass: "bar-segment",
          class: { inactive: _vm.inactive, disabled: _vm.disabled },
          style: _vm.style,
        },
        [
          _vm.show_bracket
            ? _c("div", { staticClass: "bracket" }, [
                _c("label", [_vm._v(_vm._s(_vm.bracket_label))]),
              ])
            : _vm._e(),
          _vm._t("default"),
          !_vm.inactive && !_vm.disable_tooltip
            ? _c("div", { staticClass: "bar-segment-tooltip" }, [
                _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _vm._t("tooltip-prefix", function () {
                      return [
                        _c("span", { staticClass: "time" }, [
                          _vm._v(_vm._s(_vm._f("milli2duration")(_vm.time))),
                        ]),
                        _c("span", { staticClass: "prefix" }, [
                          _vm._v(_vm._s(_vm.tooltip_prefix)),
                        ]),
                      ]
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }