var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "milestones-container", style: _vm.cssStyles },
    [
      _c(
        "div",
        { staticClass: "day-grid" },
        [
          _vm._l(_vm.cur_node.milestones, function (milestone) {
            return _c(
              "div",
              {
                key: _vm.keyForMilestone(milestone),
                staticClass: "milestone-cell-container",
                style: {
                  left: `${_vm.getMilestoneLeftOffset(milestone.date)}px`,
                },
              },
              [
                _c("milestone-cell", {
                  attrs: { milestone: milestone, cur_node: _vm.cur_node },
                  on: { "update:node": (p) => _vm.$emit("update:node", p) },
                }),
              ],
              1
            )
          }),
          _vm.cur_node.currentStatus
            ? _c(
                "div",
                {
                  staticClass: "milestone-cell-container",
                  style: {
                    left: `${_vm.getStatusLeftOffset(
                      _vm.cur_node.currentStatus.created_at
                    )}px`,
                  },
                },
                [
                  _c("project-status-cell", {
                    attrs: {
                      project_status: _vm.cur_node.currentStatus,
                      cur_node: _vm.cur_node,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }