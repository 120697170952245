var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container", style: _vm.cssStyles }, [
    _c("div", { staticClass: "top-panel" }, [
      _c("div", { staticClass: "content-controls" }, [
        _c(
          "div",
          { staticClass: "info-level-picker" },
          [
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.handleCollapseAll } },
              [_vm._v(" Collapse All ")]
            ),
            _c(
              "el-button",
              { attrs: { size: "mini" }, on: { click: _vm.handleExpandAll } },
              [_vm._v(" Expand All ")]
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticStyle: { display: "flex", "align-items": "center" } },
          [
            _c("el-switch", {
              staticStyle: { "margin-right": "20px" },
              attrs: { value: _vm.filterShyNodes, size: "mini" },
              on: { input: _vm.toggleFilterShyNodes },
            }),
            _c(
              "div",
              [
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleShyAll } },
                  [_vm._v(" Shy All ")]
                ),
                _c(
                  "el-button",
                  { attrs: { size: "mini" }, on: { click: _vm.handleShyNone } },
                  [_vm._v(" Shy None ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "resolution-picker" },
        [
          _c(
            "el-checkbox-group",
            {
              staticClass: "resolution-checkbox-group",
              attrs: { value: _vm.checkList, size: "mini" },
              on: { input: _vm.handleCheckboxChange },
            },
            [
              _c("el-checkbox-button", { attrs: { label: "day" } }),
              _c("el-checkbox-button", { attrs: { label: "week" } }),
              _c("el-checkbox-button", { attrs: { label: "month" } }),
            ],
            1
          ),
          _c(
            "el-button",
            { attrs: { size: "mini" }, on: { click: _vm.scrollToToday } },
            [_vm._v("Today ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "zoom-picker" },
        [
          _c(
            "el-select",
            {
              attrs: { value: _vm.zoomValue, size: "mini" },
              on: { input: _vm.handleSetZoomValue },
            },
            _vm._l(_vm.zoomOptions, function (option) {
              return _c("el-option", {
                key: option,
                attrs: { label: `${option}%`, value: option },
              })
            }),
            1
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [{ name: "dragscroll", rawName: "v-dragscroll" }],
        staticClass: "timeline",
        attrs: { id: "timeline" },
        on: { scroll: _vm.handleVerticalScroll },
      },
      [
        _c(
          "div",
          { staticClass: "timeline-left-panel" },
          [
            _c("div", { staticClass: "timeline-bar-spacer" }),
            _c("tree-list", {
              attrs: { nodes: _vm.tree.children, depth: 0, cur_node: _vm.tree },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "chart-box",
            style: { width: `${_vm.dimensions.width}px` },
          },
          [
            _c("div", { staticClass: "timeline-bar-spacer" }),
            _c("tree-chart", {
              attrs: {
                cur_node: _vm.tree,
                nodes: _vm.tree.children,
                day_width: _vm.DAY_WIDTH,
                row_height: _vm.ROW_HEIGHT,
                depth: 0,
              },
            }),
          ],
          1
        ),
        _c("timeline-bg-lines", {
          attrs: {
            resolutions: _vm.resolutions,
            vertical_scroll_offset: _vm.verticalScrollOffset,
            day_width: _vm.DAY_WIDTH,
            row_height: _vm.ROW_HEIGHT,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }