var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    { attrs: { content: _vm.project_status.content, placement: "top" } },
    [
      _c("div", { staticClass: "project-status-cell" }, [
        _c("div", {
          staticClass: "marker",
          style: { backgroundColor: _vm.backgroundColor },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }