var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-popover",
    {
      attrs: {
        placement: "top",
        title: _vm.title,
        trigger: "click",
        "popper-class": "milestone-popover",
        disabled: _vm.isAutomaticMilestone,
      },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "el-tooltip",
        {
          attrs: {
            slot: "reference",
            content: _vm.milestone.content,
            placement: "top",
          },
          slot: "reference",
        },
        [
          _c(
            "div",
            {
              key: _vm.milestone.date.unix(),
              class: [
                "milestone-cell",
                _vm.isAutomaticMilestone && "auto",
                _vm.isDayOmitted && "hidden",
                _vm.milestone.meta.type,
              ],
              attrs: { "is-milestone": _vm.isMilestone },
            },
            [
              _vm.isMilestone
                ? _c("div", {
                    class: [
                      "milestone",
                      _vm.isAutomaticMilestone && "auto",
                      _vm.milestone.meta.type,
                      _vm.milestone.meta.external ? "external" : "internal",
                    ],
                  })
                : _vm._e(),
            ]
          ),
        ]
      ),
      _c(
        "div",
        [
          _c("el-input", {
            attrs: { placeholder: "Content", size: "mini" },
            model: {
              value: _vm.content,
              callback: function ($$v) {
                _vm.content = $$v
              },
              expression: "content",
            },
          }),
          _c("el-date-picker", {
            attrs: { size: "mini", format: "dd/MM/yyyy" },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _c("el-switch", {
            attrs: {
              size: "mini",
              "active-text": "External",
              "inactive-text": "Internal",
            },
            model: {
              value: _vm.external,
              callback: function ($$v) {
                _vm.external = $$v
              },
              expression: "external",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "right", "margin-top": "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "danger" },
              on: { click: _vm.handleDelete },
            },
            [_vm._v(" delete ")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "text" },
              on: { click: _vm.handleCancel },
            },
            [_vm._v(" cancel ")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.handleSave },
            },
            [_vm._v(" save ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }