var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "inner" },
      [
        _c(
          "div",
          { staticClass: "shy-icon" },
          [
            _c("el-checkbox", {
              attrs: { value: !_vm.isNodeShy(_vm.cur_node) },
              on: {
                input: function ($event) {
                  return _vm.$emit("toggle:shy")
                },
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "label",
            on: {
              click: function ($event) {
                return _vm.$emit("click", _vm.cur_node)
              },
            },
          },
          [
            _c("span", [_vm._v(_vm._s(_vm.cur_node.label))]),
            _c("project-status-tag", {
              attrs: { status: _vm.currentStatusType, size: "mini" },
            }),
          ],
          1
        ),
        _c(
          "el-popover",
          {
            attrs: {
              placement: "right",
              title: "Add Milestone",
              trigger: "click",
              "popper-class": "milestone-popover",
            },
            model: {
              value: _vm.visible,
              callback: function ($$v) {
                _vm.visible = $$v
              },
              expression: "visible",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "add-button",
                attrs: { slot: "reference" },
                slot: "reference",
              },
              [_c("i", { staticClass: "el-icon-circle-plus-outline" })]
            ),
            _c(
              "div",
              [
                _c(
                  "el-form",
                  [
                    _c("el-input", {
                      attrs: { placeholder: "Content", size: "mini" },
                      model: {
                        value: _vm.content,
                        callback: function ($$v) {
                          _vm.content = $$v
                        },
                        expression: "content",
                      },
                    }),
                    _c("el-date-picker", {
                      attrs: { size: "mini", format: "dd/MM/yyyy" },
                      model: {
                        value: _vm.date,
                        callback: function ($$v) {
                          _vm.date = $$v
                        },
                        expression: "date",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticStyle: { "text-align": "right", "margin-top": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "text" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v(" cancel ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", size: "mini" },
                    on: { click: _vm.handleSave },
                  },
                  [_vm._v(" save ")]
                ),
              ],
              1
            ),
          ]
        ),
        _vm.has_children
          ? _c(
              "div",
              {
                staticClass: "action",
                on: {
                  click: function ($event) {
                    return _vm.$emit("toggle:hide")
                  },
                },
              },
              [
                _vm.children_collapsed
                  ? _c("span", { staticClass: "el-icon-caret-right" })
                  : _c("span", { staticClass: "el-icon-caret-bottom" }),
              ]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }