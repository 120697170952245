var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("timeline", {
    attrs: {
      tree: _vm.tree,
      default_hidden: _vm.default_hidden,
      ready: _vm.has_mounted,
    },
    on: {
      "update:node": _vm.handleUpdateNode,
      "click:node": _vm.handleNodeClick,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }