var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "bg-lines" },
    [
      _c(
        "div",
        {
          staticClass: "background",
          style: { width: `${_vm.days.length * _vm.day_width}px` },
        },
        [
          _c("div", { staticClass: "blur" }),
          _c("div", { staticClass: "opacity" }),
        ]
      ),
      _vm._l(_vm.days, function (item) {
        return _c(
          "div",
          {
            key: `bg-line-${item}`,
            class: [
              "bg-line-container",
              ..._vm.bgLineClasses(item),
              { today: _vm.isToday(item) },
            ],
            style: {
              marginLeft: `${
                _vm.workDaysBetween(_vm.adjustedStartDate(_vm.tree), item) *
                _vm.day_width
              }px`,
              ..._vm.cssStyles,
            },
          },
          [
            _c("div", {
              class: ["bg-line", ..._vm.bgLineClasses(item)],
              attrs: { id: _vm.isToday(item) && "today" },
            }),
            _c("div", { staticClass: "markers" }, [
              _vm.isToday(item) ||
              ((_vm.hasClass(item, "day") || _vm.hasClass(item, "week")) &&
                !_vm.isDayOmitted(item))
                ? _c("div", { staticClass: "date-marker label" }, [
                    _c("span", { staticClass: "number" }, [
                      _vm._v(_vm._s(item.format("D"))),
                    ]),
                    _c("span", { staticClass: "day-week" }, [
                      _vm._v(_vm._s(item.format("ddd"))),
                    ]),
                  ])
                : _vm._e(),
              _vm.hasClass(item, "month")
                ? _c("div", { staticClass: "month-marker label" }, [
                    _c("span", [
                      _vm._v(" " + _vm._s(item.format("MMM")) + " "),
                    ]),
                  ])
                : _vm._e(),
              _vm.hasClass(item, "week")
                ? _c("div", { staticClass: "week-marker label" }, [
                    _c("span", [_vm._v("W" + _vm._s(item.isoWeek()) + " ")]),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }