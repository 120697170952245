var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "tree-chart-container", style: _vm.containerStyle },
    [
      _vm.cur_node.chartComponent
        ? _c(
            "div",
            { staticClass: "row", style: _vm.rowStyle },
            [
              _c(_vm.cur_node.chartComponent, {
                tag: "component",
                attrs: {
                  tree: _vm.tree,
                  cur_node: _vm.cur_node,
                  day_width: _vm.day_width,
                  row_height: _vm.row_height,
                  depth: _vm.depth,
                  days: _vm.days,
                },
                on: { "update:node": _vm.handleUpdateNode },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.showChildren(_vm.cur_node)
        ? _vm._l(_vm.filteredNodes, function (node) {
            return _c("tree-chart", {
              key: `tree-chart-${node.id}`,
              attrs: {
                cur_node: node,
                depth: _vm.depth + 1,
                day_width: _vm.day_width,
                row_height: _vm.row_height,
                nodes: node.children,
              },
            })
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }